import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "content"];

  disconnect() {
    if (
      this.contentTarget &&
      !this.contentTarget.classList.contains("hidden")
    ) {
      this.contentTarget.classList.add("hidden");
    }
  }

  toggleContent() {
    if (this.hasContentTarget) {
      this.contentTarget.classList.toggle("hidden");
    }
  }
}
