import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["choiceField", "deleteField", "isAnswer"];

  connect() {
    console.debug("Choice controller connected");
  }

  deleteChoice() {
    this.choiceFieldTarget.classList.add("hidden");
    this.deleteFieldTarget.value = true;
    this.isAnswerTarget.value = null;
  }
}
