import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "hamburger",
    "cross",
    "menu"
  ];

  openMenu() {
    document.body.classList.add("noscroll");
    this.hamburgerTarget.classList.add("hidden");
    this.crossTarget.classList.remove("hidden");
    this.menuTarget.classList.remove("hidden");
  }
  closeMenu() {
    document.body.classList.remove("noscroll");
    this.hamburgerTarget.classList.remove("hidden");
    this.crossTarget.classList.add("hidden");
    this.menuTarget.classList.add("hidden");
  }
}
