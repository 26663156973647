import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["campaignFilterForm", "close"];

  connect() {
    this.filterLineIconPath = this.element.dataset.filterLineIconPath;
    this.closeIconPath = this.element.dataset.closeIconPath;
  }

  disconnect() {
    if (
      this.campaignFilterFormTarget &&
      !this.campaignFilterFormTarget.classList.contains("hidden")
    ) {
      this.campaignFilterFormTarget.classList.add("hidden");
    }
  }

  toggleFilter() {
    this.campaignFilterFormTarget.classList.toggle("hidden");
    if (this.campaignFilterFormTarget.classList.contains("hidden")) {
      this.closeTarget.classList.remove("campaign-filter__button--active");
      this.closeTarget.querySelector("img").src = this.filterLineIconPath;
    } else {
      this.closeTarget.classList.add("campaign-filter__button--active");
      this.closeTarget.querySelector("img").src = this.closeIconPath;
    }
  }
}
