import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "fileInfo", "fileName", "fileSize", "defaultValueInfo"];

  connect() {

  }

  showFileInfo(e) {
    if (e.target.value) {
      this.fileInfoTarget.classList.remove("hidden")
      const fileName = e.target.files[0].name
      const fileSize = (e.target.files[0].size / (1024*1024)).toFixed(2)
      this.fileNameTarget.innerHTML = fileName
      this.fileSizeTarget.innerHTML = fileSize + " MB"
    }
  }

  removeFile(e) {
    this.inputTarget.value = "";
    this.fileInfoTarget.classList.add("hidden")
  }

  removeDefaultValue() {
    this.defaultValueInfoTarget.classList.add("hidden")
    this.inputTarget.value = nil;
  }
}
