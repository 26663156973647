import { Controller } from "@hotwired/stimulus";
import { QUESTION_DELETED_EVENT } from "../constants";

export default class extends Controller {
  static targets = ["questionPanel", "deleteField"];

  connect() {
    this.counter = document.querySelector("#question-counter");
  }

  deleteQuestion() {
    if (confirm("Are you sure you want to remove this question?")) {
      const previewButton = document.querySelector("#task_preview");
      if (previewButton) {
        previewButton.classList.add("btn-primary--disabled");
      }
      this.questionPanelTarget.classList.add("hidden");
      this.deleteFieldTarget.value = true;
      this.counter.dispatchEvent(new CustomEvent(QUESTION_DELETED_EVENT), {
        bubbles: true,
      });
    }
  }
}
