import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["preview", "cancel"];

  connect() {
    this._checkForUnsavedChanges = this._checkForUnsavedChanges.bind(this);
    window.addEventListener("beforeunload", this._checkForUnsavedChanges);
  }

  disconnect() {
    window.removeEventListener("beforeunload", this._checkForUnsavedChanges);
  }

  onInputChange(event) {
    if (this.hasPreviewTarget) {
      this.previewTarget.classList.add("btn-primary--disabled");
    }
  }

  handleLinkClick(event) {
    if (this.hasUnsavedChanges()) {
      if (
        !confirm("You have unsaved changes. Are you sure you want to leave?")
      ) {
        event.preventDefault();
      }
    }
  }

  detectAndPreventIfChanged(event) {
    if (this.hasUnsavedChanges()) {
      event.preventDefault();
    }
  }

  hasUnsavedChanges() {
    return (
      this.hasPreviewTarget &&
      this.previewTarget.classList.contains("btn-primary--disabled")
    );
  }

  _checkForUnsavedChanges(event) {
    const hasUnsavedChanges = this.hasUnsavedChanges();

    if (hasUnsavedChanges) {
      const message =
        "You have unsaved changes. Are you sure you want to leave?";
      event.returnValue = message; // Required for the prompt to show
      return message; // For older browsers
    }
  }
}
