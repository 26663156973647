import { get, post } from "@rails/request.js";

const TurboRequest = {
  get: (url, options = {}) => {
    return get(url, {
      ...options,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        ...options.headers,
      },
    });
  },

  post: (url, options = {}) => {
    return post(url, {
      ...options,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        ...options.headers,
      },
    });
  },
};

export default TurboRequest;
