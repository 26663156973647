import { Controller } from "@hotwired/stimulus";
import { QUESTION_DELETED_EVENT } from "../constants";

export default class extends Controller {
  static targets = ["counter"];

  connect() {
    this.counterTarget.addEventListener(QUESTION_DELETED_EVENT, () => this.decreaseCount())
  }

  decreaseCount() {
    this.counterTarget.innerHTML = parseInt(this.counterTarget.innerHTML) - 1
  }

  increaseCount() {
    this.counterTarget.innerHTML = parseInt(this.counterTarget.innerHTML) + 1
  }
}
