import Sortable from '@stimulus-components/sortable'

export default class extends Sortable {
  onUpdate(event) {
    super.onUpdate(event)

    const inputs = this.element.querySelectorAll('[data-position]')
    inputs.forEach((element, index) => element.value = index + 1)
  }
}
