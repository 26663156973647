import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class TagConditionController extends Controller {
  static targets = [
    "datasources",
    "table",
    "field",
    "operator",
    "value",
    "fieldType",
  ];

  tableWithFields = null;
  operators = null;

  async connect() {
    this.conditionIndex = this.element.dataset.conditionIndex;

    const operator = this.operatorTarget?.dataset?.defaultValue;
    if (operator) {
      this._hideInputValueIfOperatorIsNullOrNotNull(
        this._getOptionElement(operator)
      );
    }

    this.datasourceOptionTarget = null;
    this.tableOptionTarget = null;
    this.fieldOptionTarget = null;
    this.operatorOptionTarget = null;

    if (
      this.datasourcesTarget &&
      this.tableTarget &&
      this.fieldTarget &&
      this.operatorTarget &&
      this.fieldTypeTarget
    ) {
      await this._prefillValues();
    }
  }

  async selectDatasource(event) {
    const datasourceId = event.target.dataset.datasourceId;
    const tables = await this._getTables(datasourceId);

    this._clearField(this.tableTarget);
    this._clearField(this.fieldTarget);
    this._clearField(this.operatorTarget);
    this.valueTarget.querySelector("input").value = "";

    this._buildFieldOptions({
      options: tables,
      element: this.tableOptionTarget,
      onClickCallback: this.selectTable,
    });
  }

  selectTable(event) {
    if (!event.target) return;
    const tableName = event.target.dataset.value;
    const table = this.tableWithFields[tableName];
    const fields = table.fields.map((field) => ({
      name: field.name,
      value: field.name,
      type: field.type,
      id: field.id,
    }));

    if (!event.initial) {
      this._clearField(this.fieldTarget);
      this._clearField(this.operatorTarget);
      this.valueTarget.querySelector("input").value = "";
    }

    this._buildFieldOptions({
      options: fields,
      element: this.fieldOptionTarget,
      onClickCallback: this.selectField,
    });
  }

  selectField(event) {
    if (!event.target) return;

    const type = event.target.dataset.type;
    this.fieldTypeTarget.value = type;

    const operatorOptions = this._buildOperatorListByType(type);

    if (!event.initial) {
      this._clearField(this.operatorTarget);
      this.valueTarget.querySelector("input").value = "";
    }

    this._buildFieldOptions({
      options: operatorOptions,
      element: this.operatorOptionTarget,
      onClickCallback: this.selectOperator,
    });
  }

  selectOperator(event) {
    if (!event.target) return;

    const operator = event.target.dataset.value;
    this.valueTarget.querySelector("input").value = "";
    this._hideInputValueIfOperatorIsNullOrNotNull(operator);
  }

  async _getTables(dsId) {
    const tables = await get(`/datasources/${dsId}/tables`);
    if (tables.ok) {
      const response_tables = await tables.json;
      let data = {};

      const tableOptions = response_tables.datasource_tables.map((table) => {
        data[table.data_source_table_name] = {
          id: table.data_source_table_id,
          fields: table.field,
        };

        return {
          name: table.data_source_table_name,
          value: table.data_source_table_name,
          id: table.data_source_table_id,
        };
      });

      this.tableWithFields = data;
      this.operators = response_tables.operators;

      return tableOptions;
    } else {
      console.error("Error fetching tables");
    }
  }

  _buildFieldOptions({ options, element, onClickCallback }) {
    const summary = element.querySelector("summary");
    const ul = element.querySelector("ul");

    summary.innerHTML = "";
    ul.innerHTML = "";

    options.forEach((option, i) => {
      const li = document.createElement("li");
      const label = document.createElement("label");

      // create label for the <li> tag
      if (option?.id) {
        label.attributes.for = option?.id;
      }
      label.dataset.value = option.name;
      label.dataset.index = i;
      label.dataset.optionId = `condition_${this.conditionIndex}_${element.dataset.fieldName}_${i}`;
      label.dataset.action = "click->dropdown-input#showSelectedValue";
      if (option.type) {
        label.dataset.type = option.type;
      }
      label.innerText = option.name;
      label.onclick = onClickCallback.bind(this);
      li.appendChild(label);

      // create input for the <summary> tag
      const input = document.createElement("input");
      input.type = "radio";
      input.value = option.value;
      input.name = `tag[conditions_attributes][${this.conditionIndex}][${element.dataset.fieldName}]`;
      input.id = `tag_conditions_attributes_${this.conditionIndex}_${element.dataset.fieldName}_${option.value}`;
      input.dataset.dropdownInputTarget = "radioBtn";

      ul.appendChild(li);
      summary.appendChild(input);
    });
  }

  _clearField(element) {
    const elementOption = this._getOptionElement(element);
    const summary = elementOption.querySelector("summary");
    const ul = elementOption.querySelector("ul");
    const textElement = element.querySelector("#selected_text");
    textElement.innerHTML = "Select";

    if (summary) {
      summary.innerHTML = "";
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = `tag[conditions_attributes][${this.conditionIndex}][${elementOption.dataset.fieldName}]`;
      input.id = `tag_conditions_attributes_${this.conditionIndex}_${elementOption.dataset.fieldName}`;
      input.value = "";
      summary.appendChild(input);
    }

    ul.innerHTML = "";
  }

  _hideInputValueIfOperatorIsNullOrNotNull(operator) {
    const input = this.valueTarget.querySelector("input");
    if (operator === "null" || operator === "not_null") {
      input.type = "hidden";
      input.value = "";
      input.disabled = true;
    } else {
      input.type = "text";
      input.disabled = false;
    }
  }

  _buildOperatorListByType(type) {
    const operators = [...this.operators["common"], ...this.operators[type]];
    return operators.map((operator) => ({
      name: operator,
      value: operator,
    }));
  }

  _getOptionElement(target) {
    // find tag details element in target
    return target.querySelector("details");
  }

  async _prefillValues() {
    this.datasourceOptionTarget = this._getOptionElement(
      this.datasourcesTarget
    );
    this.tableOptionTarget = this._getOptionElement(this.tableTarget);
    this.fieldOptionTarget = this._getOptionElement(this.fieldTarget);
    this.operatorOptionTarget = this._getOptionElement(this.operatorTarget);

    const datasourcesOption = Array.from(
      this.datasourceOptionTarget.querySelectorAll("li label")
    );

    const datasourceDefaultValue =
      this.datasourceOptionTarget.dataset.defaultValue;

    const datasourceSelectedValue = datasourcesOption.find((option) => {
      return option.dataset.datasourceValue === datasourceDefaultValue;
    });

    const datasourceId = datasourceSelectedValue?.dataset?.datasourceId;

    if (!datasourceId) {
      return;
    }

    const tables = await this._getTables(datasourceId);

    this._buildFieldOptions({
      options: tables,
      element: this.tableOptionTarget,
      onClickCallback: this.selectTable,
    });

    const tableDefaultValue = this.tableOptionTarget.dataset.defaultValue;
    const tableSelectedValue = this.tableOptionTarget.querySelector(
      `li label[data-value="${tableDefaultValue || "Select"}"]`
    );
    this.selectTable({ target: tableSelectedValue, initial: true });

    const fieldDefaultValue = this.fieldOptionTarget.dataset.defaultValue;
    const fieldSelectedValue = this.fieldOptionTarget.querySelector(
      `li label[data-value="${fieldDefaultValue || "Select"}"]`
    );

    this.selectField({ target: fieldSelectedValue, initial: true });
  }
}
