import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "sidebar"];

  toggleSidebar() {
    this.buttonTarget.classList.toggle("admin__sidebar--toggle-button--shrink");
    this.sidebarTarget.classList.toggle("admin__sidebar--shrink");
  }
}
