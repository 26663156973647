import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slider", "value", "line", "thumb" ];

  connect() {
    this.sliderTarget.addEventListener('input', this.showSliderValue(), false);
  }

  showValue() {
    this.showSliderValue();
  }

  showSliderValue() {
    const value = this.sliderTarget.value
    this.thumbTarget.innerHTML = value
    const bulletPosition = this.sliderTarget.value / this.sliderTarget.max
    const space = this.sliderTarget.offsetWidth - this.thumbTarget.offsetWidth
    this.thumbTarget.style.left = (bulletPosition * space) + 'px'
    this.lineTarget.style.width = this.sliderTarget.value + '%';
  }
}
