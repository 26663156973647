import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const useTurboStream = this.element.dataset.useTurboStream;

    if (useTurboStream) {
      const links = this.element.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("data-turbo-stream", "");
      });
    }
  }
}
