import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["clearButton", "input"];

  connect() {
    this.toggleClearButton();
  }

  toggleClearButton() {
    if (this.inputTarget.value !== "") {
      this.clearButtonTarget.classList.remove("hidden");
    } else {
      this.clearButtonTarget.classList.add("hidden");
    }
  }

  clearSearch() {
    this.inputTarget.value = "";
    this.clearButtonTarget.classList.add("hidden");
  }
}
