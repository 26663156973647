import { Controller } from "@hotwired/stimulus";
import TurboRequest from "../helpers/turbo-request";
import { getUrlWithParams } from "../helpers/request-formatter";

export default class extends Controller {
  static targets = ["featuredPanel", "featuredCheckbox", "form"];

  connect() {
    this.taskSetPath = this.element.dataset.taskSetPath;
    this.addTaskSetButtonUrl = this.element.dataset.addTaskSetButtonUrl;
    this.audiencesFilterUrl = this.element.dataset.audiencesFilterUrl;
    this.clearCampaignFilterUrl = this.element.dataset.clearCampaignFilterUrl;

    const element = document.querySelector(
      '[data-dropdown-input-target="text"]'
    );

    this.audienceTypeOldValue = null;
    if (element) {
      this.audienceTypeOldValue = element.innerHTML.trim().toLowerCase();
    }

    if (this.featuredCheckboxTarget.checked) {
      this.featuredPanelTarget.classList.remove("hidden");
    }

    window.addEventListener(
      "beforeunload",
      this._clearCampaignFilter.bind(this)
    );
  }

  disconnect() {
    this.clearCampaignFilterUrl &&
      TurboRequest.get(this.clearCampaignFilterUrl);
  }

  toggleFeaturedPanel() {
    this.featuredPanelTarget.classList.toggle("hidden");
  }

  confirmSubmitBeforeRedirectToTaskSetPage(event) {
    if (!this.hasFormTarget) {
      return;
    }

    const CONFIRM_MSG =
      "The campaign will be automatically created. Do you want to proceed?";

    if (confirm(CONFIRM_MSG)) {
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "campaign[redirect_to]");
      hiddenField.setAttribute("value", this.taskSetPath);
      this.formTarget.appendChild(hiddenField);

      this.formTarget.submit();
    } else {
      event.preventDefault();
    }
  }

  async selectAudienceType(event) {
    const audienceType = event.target.dataset.audienceType;
    const audienceTypeKey = event.target.dataset.audienceTypeKey;

    if (this.audienceTypeOldValue === audienceTypeKey) {
      return;
    }

    this.audienceTypeOldValue = audienceTypeKey;

    // update the audience filter and add task set button urls
    const audienceTypeUrl = getUrlWithParams(this.audiencesFilterUrl, [
      ["audience_type", audienceType],
    ]);
    const buttonUrl = getUrlWithParams(this.addTaskSetButtonUrl, [
      ["audience_type", audienceType],
    ]);

    await TurboRequest.get(audienceTypeUrl);
    await TurboRequest.get(buttonUrl);

    this._clearTaskSetsList();
  }

  _clearTaskSetsList() {
    const taskSetsList = document.getElementById("task_sets_list");
    taskSetsList.innerHTML = "";
    if (taskSetsList.classList.contains("admin__taskset__task-list")) {
      taskSetsList.classList.remove("admin__taskset__task-list");
      taskSetsList.classList.add("admin__taskset__task-list--empty");
    }
  }

  _clearCampaignFilter() {
    if (this.clearCampaignFilterUrl) {
      TurboRequest.get(this.clearCampaignFilterUrl);
    }
    window.removeEventListener(
      "beforeunload",
      this._clearCampaignFilter.bind(this)
    );
  }
}
