const getUrlWithParams = (_url, params) => {
  const url = new URL(_url);
  const urlParams = new URLSearchParams(url.search);

  params.forEach(([key, value]) => {
    urlParams.set(key, value);
  });

  url.search = urlParams.toString();
  return url.href;
};

export { getUrlWithParams };
