import { Controller } from "@hotwired/stimulus";
import TurboRequest from "../helpers/turbo-request";
import { getUrlWithParams } from "../helpers/request-formatter";

export default class extends Controller {
  static targets = ["taskSetIds", "clearButton", "input"];

  connect() {
    this.taskSetListUrl = this.element.dataset.showTaskSetsListUrl;
    this.selectTaskSetsUrl = this.element.dataset.selectTaskSetsUrl;
    this.isSelectAll = this.element.dataset.selectAll === "true";
    this.page = this.element.dataset.page;
    this.toggleClearButton();

    this.selectedItems = JSON.parse(this.element.dataset.selectedItems);

    this.taskSetIdsTargets.forEach((task) => {
      if (this.selectedItems[task.value]) {
        task.checked = true;
      }
    });
  }

  select(event) {
    const taskSetId = parseInt(event.target.value);
    const isChecked = event.target.checked;

    if (isChecked) {
      this._addSelectedItems(taskSetId);
    } else {
      this._removeSelectedItems(taskSetId);
    }

    const url = getUrlWithParams(this.taskSetListUrl, [
      ["selected_items", JSON.stringify(this.selectedItems)],
      ["page", this.page],
      ["q[name_i_cont]", this.inputTarget.value],
      ["select_all", "false"],
    ]);

    TurboRequest.get(url);
  }

  selectAll(event) {
    const selectAllValue = event.target.checked ? "true" : "false";

    const url = getUrlWithParams(this.taskSetListUrl, [
      ["select_all", selectAllValue],
      ["page", this.page],
    ]);

    TurboRequest.get(url);
  }

  saveSelection() {
    const url = getUrlWithParams(this.selectTaskSetsUrl, [
      ["selected_items", JSON.stringify(this.selectedItems)],
      ["page", this.page],
    ]);

    TurboRequest.get(url);
  }

  toggleClearButton() {
    if (this.inputTarget.value !== "") {
      this.clearButtonTarget.classList.remove("hidden");
    } else {
      this.clearButtonTarget.classList.add("hidden");
    }
  }

  clearSearch() {
    this.inputTarget.value = "";
    this.clearButtonTarget.classList.add("hidden");
  }

  handleFormSubmit(event) {
    event.preventDefault();

    const url = getUrlWithParams(this.taskSetListUrl, [
      ["selected_items", JSON.stringify(this.selectedItems)],
      ["select_all", this.isSelectAll],
      ["q[name_i_cont]", this.inputTarget.value],
    ]);

    TurboRequest.get(url);
  }

  _addSelectedItems(item) {
    this.selectedItems[item] = item;
  }

  _removeSelectedItems(item) {
    delete this.selectedItems[item];
  }
}
